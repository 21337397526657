const {moveToAnchor} = require("./src/utils/moveToAnchor")
const smoothscroll = require('smoothscroll-polyfill')

exports.shouldUpdateScroll = ({
                                  routerProps: {location},
                                  getSavedScrollPosition
                              }) => {
    const currentPosition = getSavedScrollPosition(location)

    if (window.location.hash) {
        moveToAnchor(window.location.hash.substr(1))
    } else {
        window.scrollTo(...(currentPosition || [0, 0]))
    }

    return false
}

exports.onClientEntry = () => {
    smoothscroll.polyfill()
}

exports.onRouteUpdate = () => {
    if (window?.HubSpotConversations?.widget?.refresh) {
        window.HubSpotConversations.widget.refresh()
    }
}
